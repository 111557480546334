
html, body {
    height: 100%;
}

// #root {
//     height: 100%;
// }

 

.box-toolbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    
    margin-bottom: 4px;
    
}

.log-datatypes {
    height: 300px;
    // background-color: black;
    color: crimson;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 14px;
    padding: 4px;
}

.message-toolbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 4px;
    height: 40px;
}

.toolbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}